export const GET_PRIVILEGES = "get_privileges"
export const GET_PRIVILEGES_SUCCESS = "get_privileges_success"

export const GET_MODULES = "get_modules"
export const GET_MODULES_SUCCESS = "get_modules_success"

export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"

export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"

export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"

export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"

export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"

export const GET_BRANCHES = "GET_BRANCHES"
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS"

export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
