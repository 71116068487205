import React, { useRef, useState } from "react"
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { setTitle } from "../../../helpers/functions"

import { MDBDataTable } from "mdbreact"
import moment from "moment"

import toastr from "toastr"

const initialData = [
  {
    id: 1,
    date: "2024-08-07",
    time: "10:00 AM",
    district_name: "Kozhikode",
    staff: "John Doe",
    action: (
      <>
        <i
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.5em",
          }}
          onClick={() => {}}
        ></i>

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={() => {}}
        ></i>
      </>
    ),
  },
  {
    id: 2,
    date: "2024-08-06",
    time: "11:00 AM",
    district_name: "Thiruvananthapuram",
    staff: "Jane Smith",
    action: (
      <>
        <i
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.5em",
          }}
          onClick={() => {}}
        ></i>

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={() => {}}
        ></i>
      </>
    ),
  },
  {
    id: 3,
    date: "2024-08-05",
    time: "12:00 PM",
    district_name: "Ernakulam",
    staff: "Alice Johnson",
    action: (
      <>
        <i
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.5em",
          }}
          onClick={() => {}}
        ></i>

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={() => {}}
        ></i>
      </>
    ),
  },
  {
    id: 4,
    date: "2024-08-04",
    time: "01:00 PM",
    district_name: "Thrissur",
    staff: "Robert Brown",
    action: (
      <>
        <i
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.5em",
          }}
          onClick={() => {}}
        ></i>

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={() => {}}
        ></i>
      </>
    ),
  },
  {
    id: 5,
    date: "2024-08-03",
    time: "02:00 PM",
    district_name: "Kannur",
    staff: "Emily Davis",
    action: (
      <>
        <i
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.5em",
          }}
          onClick={() => {}}
        ></i>

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={() => {}}
        ></i>
      </>
    ),
  },
]

const District = () => {
  const formRef = useRef()

  const [master, setMaster] = useState({})

  const [rows, setRows] = useState(initialData)

  const handleSubmit = () => {
    toastr.success("District Added Successfully")

    setRows(pre => [
      ...pre,
      {
        ...master,
        id: pre.length + 1,
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
        staff: "Admin",
        action: initialData[0].action,
      },
    ])

    formRef.current.reset()
    setMaster({})
  }

  const handleChange = e => {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Name",
        field: "district_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("District")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Name</Label>
                          <AvField
                            name="district_name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.district_name || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mt-4 pt-1">
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="districtTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default District
