import { GET_MENU, GET_MENU_SUCCESSFUL, GET_MENU_FAILED } from "./actionTypes"

export const getMenu = data => {
  return {
    type: GET_MENU,
    payload: data,
  }
}

export const getMenuSuccessful = data => {
  return {
    type: GET_MENU_SUCCESSFUL,
    payload: data.data,
  }
}

export const getMenuFailed = data => {
  return {
    type: GET_MENU_FAILED,
    payload: data,
  }
}
