import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

import { Modal } from "react-bootstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import {
  getDistrictOptions,
  getStatesOptions,
} from "../../../store/options/actions"
import axios from "axios"
import { API_URL, APP_NAME } from "../../../config"
import toastr from "toastr"
import { del, get, post, put } from "../../../helpers/api_helper"
import "./styles.scss"
import Swal from "sweetalert2"

const Company = props => {
  document.title = `Company | ${APP_NAME}`

  const options = props.options

  const dispatch = useDispatch()

  const [master, setMaster] = useState({})
  const [details, setDetails] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [companyDetails, setCompanyDetails] = useState({})
  const [modal, setModal] = useState(false)
  const [reload, setReload] = useState(false)

  const formRef = useRef()

  const { states, distrcts } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getStatesOptions({}))
  }, [dispatch])

  useEffect(() => {
    get("company").then(res => {
      const data = res.companies
      data.map((item, idx) => {
        item.id = idx + 1

        item.district_name = item?.district?.district_name || ""

        item.staff_name = `${item.added_by.firstName || ""} ${
          item.added_by.lastName || ""
        }`

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {options.view && (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleModal(item._id)
                }}
              ></i>
            )}
            {options.view && (
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  preUpdateCompany(item._id)
                  toTop()
                }}
              ></i>
            )}
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )
        return item
      })
      setDetails(data)
    })
    // eslint-disable-next-line
  }, [reload, options])

  function handleChange(e) {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`company/${id}`)
          .then(res => {
            toastr.success(res.message)
            setReload(pre => !pre)
            handleReset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function preUpdateCompany(id) {
    get(`company/single/${id}`)
      .then(res => {
        const data = res.company
        data.id = id
        setSelectedState({ label: data?.state?.name, value: data?.state?._id })
        setSelectedDistrict({
          label: data?.district?.district_name,
          value: data?.district?._id,
        })
        data.district = data?.district?._id
        data.state = data?.state?._id
        setMaster(data)
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleModal(id) {
    get(`company/single/${id}`)
      .then(res => {
        const data = res.company
        data.district_name = data?.district?.district_name
        data.state_name = data?.state?.name
        setCompanyDetails(data)
        setModal(true)
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  function handleSelectChange(e, name) {
    const data = { ...master }
    switch (name) {
      case "state":
        dispatch(getDistrictOptions({ id: e.value }))
        setSelectedState(e)
        data[name] = e.value
        data.district = null
        setSelectedDistrict(null)
        break
      case "district":
        setSelectedDistrict(e)
        data[name] = e.value
        break

      default:
        break
    }
    setMaster(data)
  }

  function handleSubmit() {
    if (master.id) {
      put("company", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          console.log(err.response.data)
          toastr.error(err.response.data.message)
        })
    } else {
      post("company", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          console.log(err.response.data)
          toastr.error(err.response.data.message)
        })
    }
  }

  const uploadImage = e => {
    const fd = new FormData()
    fd.append("company_image", e.target.files[0])
    axios
      .post(`${API_URL}company/image`, fd, {
        // onUploadProgress: data => {
        //   // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        // },
      })
      .then(response => {
        if (response.data.status === 200) {
          setMaster({
            ...master,
            logo: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
  }

  function handleReset() {
    formRef.current.reset()
    setMaster({})
    setSelectedDistrict(null)
    setSelectedState(null)
  }

  const data = {
    columns: [
      {
        label: "Company ID",
        field: "unqid",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "shortcode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "person",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "mob",
        sort: "asc",
        width: 10,
      },
      {
        label: "District",
        field: "district_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "staff_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  }

  return (
    <React.Fragment>
      <Modal show={modal} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Company Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <table
            id="product_available_day"
            className="table table-bordered dataTable"
          >
            <tr>
              <td style={{ width: "184px", padding: "10px" }}>
                Company Name :
              </td>
              <td>{companyDetails?.name}</td>
              <td>Company ID :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.unqid}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Date :</td>
              <td>{companyDetails?.date}</td>
              <td>Time :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.time}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Company Code :</td>
              <td>{companyDetails?.shortcode}</td>
              <td>Contact Person :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.person}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Mobile :</td>
              <td>{companyDetails?.mob}</td>
              <td>Designation :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.design}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>District :</td>
              <td>{companyDetails?.district_name}</td>
              <td>State :</td>
              <td style={{ textAlign: "left" }}>
                {companyDetails?.state_name}
              </td>
            </tr>
            <tr hidden={!companyDetails?.logo}>
              <td style={{ padding: "10px" }}>Logo :</td>
              <td style={{ textAlign: "left" }}>
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    paddingLeft: "0px",
                  }}
                  alt=""
                  src={`${API_URL}${companyDetails?.logo}`}
                ></img>
              </td>
            </tr>
          </table>
        </div>
      </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Company" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Company Name
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Company Name"
                            type="text"
                            errorMessage="Enter Company Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.name || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="shortcode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.shortcode || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Contact Person
                          </Label>
                          <AvField
                            name="person"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.person || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Designation
                          </Label>
                          <AvField
                            name="design"
                            placeholder="Designation"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.design || ""}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="mob"
                            placeholder="Mobile No."
                            type="text"
                            errorMessage="Enter valid mobile no"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "(0|91)?[6-9][0-9]{9}",
                              },
                            }}
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master.mob || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Landline No.
                          </Label>
                          <AvField
                            name="land"
                            placeholder="Landline No."
                            type="text"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9]{11}$",
                              },
                            }}
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master.land || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage=" Please provide a valid Email ID."
                            className="form-control"
                            validate={{ email: true }}
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.email || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Website</Label>
                          <AvField
                            name="web"
                            placeholder="Website"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.web || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            placeholder="Address"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.address || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Select State</Label>
                          <Select
                            name="state_id"
                            value={selectedState}
                            onChange={selected => {
                              handleSelectChange(selected, "state")
                            }}
                            options={states}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district_id"
                            value={selectedDistrict}
                            onChange={selected => {
                              handleSelectChange(selected, "district")
                            }}
                            options={distrcts}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">PIN</Label>
                          <AvField
                            name="pin"
                            placeholder="PIN"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "PIN must be only with numbers",
                              },
                            }}
                            value={master?.pin || ""}
                            min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">GSTIN</Label>
                          <AvField
                            name="gstin"
                            placeholder="GSTIN"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.gstin || ""}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">PAN No.</Label>
                          <AvField
                            name="pan"
                            placeholder="PAN No"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master?.pan || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">CIN No.</Label>
                          <AvField
                            name="cin"
                            placeholder="CIN No."
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.cin || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">TDS No.</Label>
                          <AvField
                            name="tds"
                            placeholder="TDS No"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.tds || ""}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="latitude"
                            placeholder="Latitude"
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.latitude || ""}
                            //value={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="number"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master?.longitude || ""}
                            //value={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo
                          </Label>
                          {master?.logo ? (
                            <div div className="img-wraps d-flex flex-column">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}${master.logo}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() =>
                                  setMaster(pre => ({ ...pre, logo: null }))
                                }
                                type="button"
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="logo"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                            />
                          )}
                        </div>
                      </Col>

                      <Col
                        md={2}
                        className="d-flex align-items-center mt-2 pt-2"
                      >
                        <Button
                          color={master?.id ? "warning" : "primary"}
                          className="me-2"
                          type="submit"
                        >
                          {master?.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          onClick={handleReset}
                          type="button"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="companyTableId"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Company
