import moment from "moment"

import { APP_NAME, CRYPTO_HASH } from "../config"

import CryptoJS from "crypto-js"

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const getFirstday = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")

export function encrypt(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_HASH,
  ).toString()
  return encrypted
}

export const decrypt = data => {
  const decrypted = CryptoJS.AES.decrypt(data, CRYPTO_HASH).toString(
    CryptoJS.enc.Utf8,
  )
  return decrypted
}

export const setTitle = (title = "") => {
  document.title = `${title} | ${APP_NAME}`
  return title
}
