import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import toastr from "toastr"
import { post, get, put, del } from "../../../helpers/api_helper"
import Swal from "sweetalert2"
import Select from "react-select"
import { APP_NAME } from "../../../config"
import { getPrivileges } from "../../../store/options/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import $ from "jquery"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
const Privilage = props => {
  document.title = `Privilage | ${APP_NAME}`

  const options = props.options
  // console.log("options: ", options)

  const [master, setMaster] = useState({})
  const [tableData, setTableData] = useState([])
  const [selectedPrivilege, setSelectedPrivilege] = useState(null)

  const [error, setError] = useState({ privilege: false })

  const { privileges } = useSelector(state => state.Options)

  const formRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrivileges())
  }, [dispatch])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleChange(e) {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  function reset() {
    formRef.current.reset()
    setMaster({})
    setSelectedPrivilege(null)
  }

  function handleSubmit() {
    let flag = false

    if (!selectedPrivilege) {
      flag = true
      $("#under_privilege").addClass("danger")
      setError(pre => ({ ...pre, privilege: true }))
    }

    if (flag) return

    if (master.id) {
      put("privilage", master)
        .then(res => {
          handleTableData()
          toastr.success(res.message)
          reset()
          dispatch(getPrivileges())
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("privilage", master)
        .then(res => {
          handleTableData()
          toastr.success(res.message)
          reset()
          dispatch(getPrivileges())
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  function preupdatePrivilege(data) {
    setMaster({
      privilege_name: data.privilege_name,
      privilege_code: data.privilege_code,
      id: data._id,
      parent: data?.parent._id,
    })
    setSelectedPrivilege({
      label: data?.parent?.privilege_name,
      value: data?.parent._id,
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`privilage/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function handleAddModule(e, privilege, module) {
    let { checked } = e.target
    let status = checked ? 1 : 0
    put("privilage/module", { status, privilege, module })
      .then(res => {
        toastr.success(res.message)
        handleTableData()
      })
      .catch(err => {
        toastr.success(err.response.data.message)
      })
  }

  function handleTableData() {
    get("privilage").then(res => {
      const data = res.privilage || []
      data.map((item, idx) => {
        item.id = idx + 1

        item.under_privilege = item?.parent?.privilege_name

        item.modules = (
          <div>
            <ul
              style={{
                listStyleType: "none",
              }}
            >
              {item.module &&
                item.module.map((el, index) => {
                  return (
                    <li style={{ textAlign: "left" }} className="mb-2">
                      <input
                        type="checkbox"
                        className="mb-2"
                        name="allocation_status"
                        id="allocation_status"
                        checked={el.allocation_status === 1 ? true : false}
                        onChange={e => handleAddModule(e, item._id, el._id)}
                      />
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        {el?.moduleName}
                      </span>
                    </li>
                  )
                })}
            </ul>
          </div>
        )
        item.action = (
          <div class="action">
            {options.edit && (
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem",
                }}
                onClick={() => {
                  toTop()
                  preupdatePrivilege(item)
                }}
              ></i>
            )}
            {options.delete && (
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(item._id)
                }}
              ></i>
            )}
          </div>
        )

        return item
      })

      setTableData(data)
    })
  }

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "privilege_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Short code",
        field: "privilege_code",
        sort: "asc",
        width: 400,
      },
      {
        label: "Under privilege",
        field: "under_privilege",
        sort: "asc",
        width: 400,
      },
      {
        label: "Modules",
        field: "modules",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  if (options.edit || options.delete) {
    data.columns.push({
      label: "Action",
      field: "action",
      width: 300,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Privilage" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => handleSubmit()}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Privilege</Label>

                          <AvField
                            name="privilege_name"
                            value={master.privilege_name || ""}
                            placeholder="Privilege"
                            type="text"
                            errorMessage="Enter Privilege"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Privilege must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="privilege_code"
                            value={master.privilege_code || ""}
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Shortcode must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom02"
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Under privilege</Label>
                          <Select
                            id="under_privilege"
                            name="privilage"
                            value={selectedPrivilege}
                            onChange={value => {
                              setSelectedPrivilege(value)
                              setMaster(pre => ({
                                ...pre,
                                parent: value.value,
                              }))
                              setError(pre => ({ ...pre, privilege: false }))
                            }}
                            options={privileges}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                        <SelectErrorMessage
                          message="Select Privilege"
                          show={error.privilege}
                        />
                      </Col>

                      <Col md="2" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={`${master.id ? "warning" : "primary"}`}
                            type="submit"
                            className="me-2"
                          >
                            {master.id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="account_type_table"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Privilage
