import { GET_MENU, GET_MENU_SUCCESSFUL, GET_MENU_FAILED } from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  data: null,
}

const Menu = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MENU_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      }
      break
    case GET_MENU_FAILED:
      state = {
        ...state,
        data: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Menu
