import * as url from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  privileges: [],
  modules: [],
  users: [],
  states: [],
  countries: [],
  distrcts: [],
  companies: [],
  branches: [],
  categories: [],
}

const Options = (state = initialState, action) => {
  switch (action.type) {
    case url.GET_PRIVILEGES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_PRIVILEGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        privileges: action.payload,
      }
      break
    case url.GET_MODULES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_MODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        modules: action.payload,
      }
      break
    case url.GET_USERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
      }
      break
    case url.GET_STATES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_STATES_SUCCESS:
      state = {
        ...state,
        loading: false,
        states: action.payload,
      }
      break
    case url.GET_COUNTRIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        countries: action.payload,
      }
      break
    case url.GET_DISTRICTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_DISTRICTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        distrcts: action.payload,
      }
      break
    case url.GET_COMPANIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        companies: action.payload,
      }
      break
    case url.GET_BRANCHES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_BRANCHES_SUCCESS:
      state = {
        ...state,
        loading: false,
        branches: action.payload,
      }
      break
    case url.GET_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Options
