import Cookies from "js-cookie"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  TOKEN_REGENERATE,
} from "./actionTypes"

export const loginUser = (user, history, dispatch) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, dispatch },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user.data,
  }
}

export const logoutUser = (history, state = false) => {
  return {
    type: LOGOUT_USER,
    payload: { history, state },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const accessToken = (history, pathname) => {
  return {
    type: TOKEN_REGENERATE,
    payload: {
      history,
      pathname,
      refreshToken: Cookies.get("_token"),
    },
  }
}
