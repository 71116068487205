import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import {
  GET_BRANCHES,
  GET_CATEGORY,
  GET_COMPANIES,
  GET_COUNTRIES,
  GET_DISTRICTS,
  GET_MODULES,
  GET_PRIVILEGES,
  GET_STATES,
  GET_USERS,
} from "./actionTypes"
import {
  getBranchesSuccessful,
  getCategorySuccessful,
  getCompaniesSuccessful,
  getCountriesSuccessful,
  getDistrictSuccessful,
  getModulesSuccessful,
  getPrivilegesSuccessful,
  getStatesSuccessful,
  getUsersSuccessful,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBranchesOptions,
  getCategoriesOptions,
  getCompaniesOptions,
  getCountriesOptions,
  getDistrictsOptions,
  getModules,
  getPrivileges,
  getStatesOptions,
  getUsersOptions,
} from "../../helpers/backend_helper"

function* fetchPrivileges({ payload }) {
  try {
    const response = yield call(getPrivileges)
    yield put(getPrivilegesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchModules({ payload }) {
  try {
    const response = yield call(getModules)
    yield put(getModulesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersOptions)
    yield put(getUsersSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchCountries() {
  try {
    const response = yield call(getCountriesOptions)
    yield put(getCountriesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchStates({ payload }) {
  try {
    const response = yield call(getStatesOptions)
    yield put(getStatesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchDistricts({ payload }) {
  try {
    const response = yield call(getDistrictsOptions, payload)
    yield put(getDistrictSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchCompanies({ payload }) {
  try {
    const response = yield call(getCompaniesOptions, payload)
    yield put(getCompaniesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchBranches({ payload }) {
  try {
    const response = yield call(getBranchesOptions, payload)
    yield put(getBranchesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchCategory({ payload }) {
  try {
    const response = yield call(getCategoriesOptions, payload)
    yield put(getCategorySuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* optionsSaga() {
  yield takeEvery(GET_PRIVILEGES, fetchPrivileges)
  yield takeEvery(GET_MODULES, fetchModules)
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_DISTRICTS, fetchDistricts)
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(GET_BRANCHES, fetchBranches)
  yield takeEvery(GET_CATEGORY, fetchCategory)
}

export default optionsSaga
