import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import OtpInput from "react-otp-input"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
// import { useSelector } from "react-redux"
// import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
// import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/sm-logo.svg"
import { APP_NAME } from "../../config"
import { post, put } from "../../helpers/api_helper"
import toastr from "toastr"
// import logolight from "../../assets/images/logo-light.png"

const ForgetPasswordPage = props => {
  document.title = `Recover Password | ${APP_NAME}`

  const [master, setMaster] = useState({
    otpShow: false,
    otp: null,
    verify: false,
  })
  const [forgetError, setForgetError] = useState(null)
  const [forgetSuccessMsg] = useState(null)
  const [counter, setCounter] = useState(0)

  const [passShow, setpassShow] = useState(false)

  const navigate = useNavigate()

  const formRef = useRef()

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  // const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email / Mobile"),
    }),
    onSubmit: values => {
      post("user/forgot-password", { username: values.email })
        .then(res => {
          toastr.success(res.message)
          setForgetError(null)
          setCounter(30)
          console.log(values)
          setMaster({ otpShow: true, username: values.email })
        })
        .catch(err => {
          setForgetError(err.response.data.message)
          // toastr.error(err.message)
        })
      // dispatch(userForgetPassword(values, props.history))
    },
  })

  const handleSubmit = () => {
    post("user/verify-otp", master)
      .then(res => {
        setCounter(0)
        setForgetError(null)
        setMaster({ id: res.user, verify: true, otpShow: true })
      })
      .catch(err => {
        setForgetError(err?.response?.data?.message)
        console.log(err)
      })
  }

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  function handlePasswordReset(resend) {
    put("user/password-reset", master)
      .then(res => {
        // setMaster(pre => ({ ...pre, show: false }))
        if (resend) {
          toastr.success("OTP resended successfully")
        } else {
          toastr.success(res.message)
        }
        setForgetError(null)
        navigate("/logout")
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  // const selectForgotPasswordState = state => state.ForgetPassword

  // const ForgotPasswordProperties = createSelector(
  //   selectForgotPasswordState,
  //   forgetPassword => ({
  //     forgetError: forgetPassword.forgetError,
  //     forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
  //   })
  // )

  // const { forgetError, forgetSuccessMsg } = useSelector(
  //   ForgotPasswordProperties
  // )

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block position-fixed">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}

      <div className="account-pages">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center logoimg">
                        <Link to="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="50"
                            className="logo logo-dark"
                          />
                          <img
                            src={logo}
                            alt=""
                            height="50"
                            className="logo logo-light"
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {master.otpShow ? "Enter OTP" : "Reset Password"}
                    </h5>
                    <p className="text-muted">
                      Reset Password with {APP_NAME}.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    {forgetError && forgetError ? (
                      <Alert
                        color="danger"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert
                        color="success"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {master.otpShow ? (
                      <>
                        {master.verify ? (
                          <AvForm
                            className="needs-validation"
                            ref={formRef}
                            onValidSubmit={(e, v) => {
                              handlePasswordReset()
                            }}
                          >
                            <div className="modal-body pt-1">
                              <Row>
                                <Col md="12">
                                  <FormGroup
                                    className="my-3 position-relative"
                                    id="confirm-pass"
                                  >
                                    <AvField
                                      name="password"
                                      placeholder="Password"
                                      type={`${passShow ? "text" : "password"}`}
                                      errorMessage=" Please provide a valid password"
                                      validate={{ required: { value: true } }}
                                      id="validationCustom05"
                                      value={master.password || ""}
                                      onChange={e =>
                                        setMaster(pre => ({
                                          ...pre,
                                          password: e.target.value,
                                        }))
                                      }
                                    />
                                    {passShow ? (
                                      <i
                                        className="fas fa-eye-slash position-absolute"
                                        onClick={() => setpassShow(pre => !pre)}
                                        style={{
                                          right: "10px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fas fa-eye position-absolute"
                                        onClick={() => setpassShow(pre => !pre)}
                                        style={{
                                          right: "10px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="confirmPassword"
                                      placeholder="Confirm Password"
                                      type={`${passShow ? "text" : "password"}`}
                                      errorMessage="Please confirm the password"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        match: {
                                          value: "password",
                                          errorMessage: "Password not match",
                                        },
                                      }}
                                      id="validationCustom05"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="modal-footer">
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </AvForm>
                        ) : (
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-3 d-flex justify-content-center">
                              <OtpInput
                                value={master.otp}
                                onChange={e =>
                                  setMaster(pre => ({ ...pre, otp: e }))
                                }
                                inputStyle={{
                                  marginRight: ".7rem",
                                  border: "1px solid #80808087",
                                  borderRadius: "5px",
                                  width: "48px",
                                  height: "40px",
                                  fontSize: "26px",
                                  color: "#808080",
                                  fontWeight: "400",
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;</span>}
                                renderInput={props => <input {...props} />}
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <Row className="mb-0">
                              <Col xs={12} className="text-center mt-3">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Verify
                                </button>
                              </Col>
                            </Row>
                            <div className="mt-4 text-center">
                              {counter === 0 ? (
                                <p className="mb-0">
                                  Didn't receive OTP ?{" "}
                                  <Link
                                    onClick={e => {
                                      e.preventDefault()
                                      validation.handleSubmit()
                                    }}
                                    to="/"
                                    className="fw-medium text-primary"
                                  >
                                    Resend
                                  </Link>
                                </p>
                              ) : (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    marginLeft: "5px",
                                  }}
                                >
                                  00:{("0" + counter).slice(-2)}
                                </span>
                              )}
                            </div>
                          </Form>
                        )}
                      </>
                    ) : (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email / Mobile</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email / mobile"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-0">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Signin{" "}
                            </Link>
                          </p>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  ©{new Date().getFullYear()} {APP_NAME}. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

export default withRouter(ForgetPasswordPage)
