import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import MarketingDashboard from "../pages/Dashboard/Marketing/index"
import WasteDashboard from "../pages/Waste-Dashboard/index"
import HKSdashboard from "../pages/hsk-dashboard/index"
import PMSdashboard from "../pages/PMS-Dashboard/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Users
import User from "../pages/User"
import UserDashboard from "../pages/User/UserDashboard"

// Master Settings
import Privilage from "../pages/MasterSettings/Privilage"
import Modules from "../pages/MasterSettings/Modules"
import Rules from "../pages/MasterSettings/Rules"
import Company from "../pages/MasterSettings/Company"
import Branch from "../pages/MasterSettings/Branch"

// Security
import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"

// Manage
import District from "../pages/Manage/District"
import Localbody from "../pages/Manage/LocalBody"
import Ward from "../pages/Manage/Ward"

//Own Pages
const userRoutes = [
  //dahshboard
  { path: "/dashboard", component: <MarketingDashboard /> },
  {
    path: "/social-dashboard",
    component: <MarketingDashboard name="Social" />,
  },




  { path: "/hks-dashboard", component: <HKSdashboard /> },
  { path: "/pms-dashboard", component: <PMSdashboard /> },
  { path: "/waste-dashboard", component: <WasteDashboard  /> },




  {
    path: "/accounts-dashboard",
    component: <MarketingDashboard name="Accounts" />,
  },
  {
    path: "/take-a-break",
    component: <MarketingDashboard />,
  },
  //Utility
  { path: "/pages", component: <PagesStarter /> },

  // Users
  { path: "/user", component: <User /> },
  { path: "/user/:user_id", component: <UserDashboard /> },

  // Master Settings
  { path: "/privilage", component: <Privilage /> },
  { path: "/rules", component: <Rules /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },

  // Security
  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },

  // Manage
  { path: "/district", component: <District /> },
  { path: "/localbody", component: <Localbody /> },
  { path: "/ward", component: <Ward /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
  {
    path: "*",
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
