import React, { useRef } from "react"
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { setTitle } from "../../../helpers/functions"

import { MDBDataTable } from "mdbreact"
import useLocalStorage from "../../../hooks/useLocalStorage"
import "./style.scss"
const initialData = [
  {
    id: 1,
    date: "2024-08-07",
    time: "10:00 AM",
    company_name: "ABC Corp",
    district_name: "Central",
    localbody_type: "Municipality",
    localbody_name: "Central City",
    short_code: "CTR",
    localbody_receipt_footer: "Thank you for your payment.",
    localbodyaddedby: "John Doe",
    action: "Edit/Delete",
  },
  {
    id: 2,
    date: "2024-08-06",
    time: "11:00 AM",
    company_name: "XYZ Ltd",
    district_name: "North",
    localbody_type: "Township",
    localbody_name: "North Town",
    short_code: "NTH",
    localbody_receipt_footer: "Please keep this receipt.",
    localbodyaddedby: "Jane Smith",
    action: "Edit/Delete",
  },
  {
    id: 3,
    date: "2024-08-05",
    time: "12:00 PM",
    company_name: "LMN Inc",
    district_name: "South",
    localbody_type: "City",
    localbody_name: "South City",
    short_code: "STH",
    localbody_receipt_footer: "Receipt for your records.",
    localbodyaddedby: "Alice Johnson",
    action: "Edit/Delete",
  },
  {
    id: 4,
    date: "2024-08-04",
    time: "01:00 PM",
    company_name: "OPQ Co",
    district_name: "East",
    localbody_type: "Village",
    localbody_name: "East Village",
    short_code: "EST",
    localbody_receipt_footer: "Payment received. Thank you.",
    localbodyaddedby: "Robert Brown",
    action: "Edit/Delete",
  },
  {
    id: 5,
    date: "2024-08-03",
    time: "02:00 PM",
    company_name: "DEF Enterprises",
    district_name: "West",
    localbody_type: "Borough",
    localbody_name: "West Borough",
    short_code: "WST",
    localbody_receipt_footer: "We appreciate your business.",
    localbodyaddedby: "Emily Davis",
    action: "Edit/Delete",
  },
]

const Localbody = () => {
  const formRef = useRef()

  const [rows, setRows] = useLocalStorage("district", initialData)

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date ",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Time ",
        field: "time",
        sort: "asc",
        width: 50,
      },
      {
        label: "Company ",
        field: "company_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "District ",
        field: "district_name",
        sort: "asc",
        width: 400,
      },

      {
        label: " Type",
        field: "localbody_type",
        sort: "asc",
        width: 400,
      },
      {
        label: " Name",
        field: "localbody_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Shortcode",
        field: "short_code",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reciept Footer",
        field: "localbody_receipt_footer",
        sort: "asc",
        width: 400,
      },
      {
        label: "Staff",
        field: "localbodyaddedby",
        sort: "asc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Localbody")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      //   handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>

                          <Select
                            name="district_name"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            // value={districtname}
                            // onChange={handleChangeDistrictName}
                            options={[
                              {
                                _id: "629b4df34d405c9d4919264f",
                                district_name: "Alappuzha",
                                label: "Alappuzha",
                                value: "629b4df34d405c9d4919264f",
                              },
                              {
                                _id: "629b4df34d405c9d49192650",
                                district_name: "Ernakulam",
                                label: "Ernakulam",
                                value: "629b4df34d405c9d49192650",
                              },
                              {
                                _id: "629b4df34d405c9d49192651",
                                district_name: "Idukki",
                                label: "Idukki",
                                value: "629b4df34d405c9d49192651",
                              },
                              {
                                _id: "629b4df34d405c9d49192652",
                                district_name: "Kannur",
                                label: "Kannur",
                                value: "629b4df34d405c9d49192652",
                              },
                              {
                                _id: "629b4df34d405c9d49192653",
                                district_name: "Kasaragod",
                                label: "Kasaragod",
                                value: "629b4df34d405c9d49192653",
                              },
                              {
                                _id: "629b4df34d405c9d49192654",
                                district_name: "Kollam",
                                label: "Kollam",
                                value: "629b4df34d405c9d49192654",
                              },
                              {
                                _id: "629b4df34d405c9d49192655",
                                district_name: "Kottayam",
                                label: "Kottayam",
                                value: "629b4df34d405c9d49192655",
                              },
                              {
                                _id: "629b4df34d405c9d49192656",
                                district_name: "Kozhikode",
                                label: "Kozhikode",
                                value: "629b4df34d405c9d49192656",
                              },
                              {
                                _id: "629b4df34d405c9d49192657",
                                district_name: "Malappuram",
                                label: "Malappuram",
                                value: "629b4df34d405c9d49192657",
                              },
                              {
                                _id: "629b4df34d405c9d49192658",
                                district_name: "Palakkad",
                                label: "Palakkad",
                                value: "629b4df34d405c9d49192658",
                              },
                              {
                                _id: "629b4df34d405c9d49192659",
                                district_name: "Pathanathitta",
                                label: "Pathanathitta",
                                value: "629b4df34d405c9d49192659",
                              },
                              {
                                _id: "629b4df34d405c9d4919265a",
                                district_name: "Thiruvananthapuram",
                                label: "Thiruvananthapuram",
                                value: "629b4df34d405c9d4919265a",
                              },
                              {
                                _id: "629b4df34d405c9d4919265b",
                                district_name: "Thrissur",
                                label: "Thrissur",
                                value: "629b4df34d405c9d4919265b",
                              },
                              {
                                _id: "629b4df34d405c9d4919265c",
                                district_name: "Wayanad",
                                label: "Wayanad",
                                value: "629b4df34d405c9d4919265c",
                              },
                            ]}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company</Label>
                          <Select
                            name="company_name"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            options={[
                              { label: "company 1", value: "company 1" },
                              { label: "company 2", value: "company 2" },
                              { label: "company 3", value: "company 3" },
                              { label: "company 4", value: "company 4" },
                            ]}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody Type</Label>

                          <Select
                            name="localbody_type"
                            id="exampleSelect"
                            style={{ appearance: "auto" }}
                            // value={localbodytype}
                            // onChange={handleChangeLocalbodyType}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Localbody Name
                          </Label>
                          <AvField
                            name="localbody_name"
                            placeholder="Localbody Name"
                            type="text"
                            errorMessage="Enter localbody Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            // value={localbodyname}
                            // onChange={handleChangeLocalbodyname}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3 mt-2">
                          <AvField
                            name="short_code"
                            placeholder="ShortCode"
                            type="text"
                            errorMessage="Enter shortcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            // value={shortcode}
                            // onChange={handleChangeShortcode}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3 mt-2">
                          <AvField
                            name="localbody_receipt_footer"
                            placeholder="Receipt Footer content"
                            type="text"
                            errorMessage="Enter Content"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom01"
                            // value={localbody_receipt_footer}
                            // onChange={handleChangereceiptContent}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3 mt-2">
                          <Button color="primary" type="submit">
                            {/* {addingLocalbody ? "Updating" : "Update"} */}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="localbodyTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Localbody
